skills {
    text-align: center;
    padding: 50px 15px;
}

.heading2 {
    position: relative;
    display: inline-block;
    font-size: 2em;
    font-weight: 300;
    margin: 0px 0px 30px;
}

.heading2::after {
    position: absolute;
    content: "";
    top: 100%;
    height: 1px;
    width: 50px;
    left: 0px;
    right: 0px;
    margin: 0px auto;
    background: rgb(52, 152, 219);
}

#skills h1 {
    color: white !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
    /* margin-bottom: 50px; */
} 

#skills ul {
    display: block;
    max-width: 800px;
    margin: 0px auto;
    padding: 0px;
}

#skills li {
    display: inline-block;
    color: rgb(55, 64, 84);
    cursor: default;
    font-size: 1.2em;
    margin: 7px;
    padding: 5px 10px;
    /* background: rgb(228, 228, 234); */
    background: var(--imp-text-color);
    list-style: none;
}